import React from "react"
import { graphql } from "gatsby"

import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Skeleton from "@material-ui/lab/Skeleton"
import Typography from "@material-ui/core/Typography"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import ToolLayout from "../../../components/tool_layout"
import CopyToClipboardButton from "../../../components/widgets/copy_to_clipboard"

import UAParser from "ua-parser-js"

export const query = graphql`
  query WebUserAgentQuery {
    allToolsJson(filter: { path: { eq: "web/user-agent" } }) {
      edges {
        node {
          path
          title
          description
          links {
            github
          }
        }
      }
    }
  }
`

class UserAgentState {
  ua: string = ""
}

class UserAgentPage extends React.Component<{ data: any }> {
  state: UserAgentState

  constructor(props: any) {
    super(props)

    this.state = new UserAgentState()
  }

  componentDidMount() {
    this.setState({
      ua: window.navigator.userAgent,
    })
  }

  featuresTable = () => {
    let parser = new UAParser(this.state.ua)
    const result = parser.getResult()

    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="browser" hover>
              <TableCell>
                <Typography variant="subtitle2">Browser</Typography>
              </TableCell>
              <TableCell>
                <Box display="flex">
                  <Typography variant="body2" noWrap>
                    {result.browser.name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex">
                  <Typography variant="body2" noWrap>
                    {result.browser.version}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow key="os" hover>
              <TableCell>
                <Typography variant="subtitle2">Operating System</Typography>
              </TableCell>
              <TableCell>
                <Box display="flex">
                  <Typography variant="body2" noWrap>
                    {result.os.name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex">
                  <Typography variant="body2" noWrap>
                    {result.os.version}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow key="os" hover>
              <TableCell>
                <Typography variant="subtitle2">Engine</Typography>
              </TableCell>
              <TableCell>
                <Box display="flex">
                  <Typography variant="body2" noWrap>
                    {result.engine.name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex">
                  <Typography variant="body2" noWrap>
                    {result.engine.version}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow key="os" hover>
              <TableCell>
                <Typography variant="subtitle2">CPU</Typography>
              </TableCell>
              <TableCell>
                <Box display="flex">
                  <Typography variant="body2" noWrap>
                    {result.cpu.architecture || "N/A"}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex">
                  <Typography variant="body2" noWrap>
                    N/A
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow key="os" hover>
              <TableCell>
                <Typography variant="subtitle2">Device</Typography>
              </TableCell>
              <TableCell>
                <Box display="flex">
                  <Typography variant="body2" noWrap>
                    {result.device.model || "N/A"}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box display="flex">
                  <Typography variant="body2" noWrap>
                    {result.device.type || "N/A"}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  contentWidget = () => {
    if (!this.state.ua) {
      return (
        <>
          {Array.from(Array(3).keys()).map(index => (
            <Skeleton animation="wave" key={index} />
          ))}
        </>
      )
    }

    return (
      <>
        <Box
          width={1}
          display="flex"
          justifyContent="center"
          fontSize="h6.fontSize"
        >
          {this.state.ua}
        </Box>
        <Box width={1} mt={4} mb={4} display="flex" justifyContent="center">
          <CopyToClipboardButton value={this.state.ua} />
        </Box>
      </>
    )
  }

  render() {
    return (
      <ToolLayout data={this.props.data}>
        <Paper>
          <Box p={4}>{this.contentWidget()}</Box>
        </Paper>

        <Box mt={2} mb={2}>
          <Paper>
            <Box p={4}>{this.featuresTable()}</Box>
          </Paper>
        </Box>
      </ToolLayout>
    )
  }
}

export default UserAgentPage
